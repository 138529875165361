export const countries = [
  { name: "Afghanistan", isoCode: "AF" },
  { name: "Albania", isoCode: "AL" },
  { name: "Algeria", isoCode: "DZ" },
  { name: "Andorra", isoCode: "AD" },
  { name: "Angola", isoCode: "AO" },
  { name: "Anguilla", isoCode: "AI" },
  { name: "Antigua and Barbuda", isoCode: "AG" },
  { name: "Argentina", isoCode: "AR" },
  { name: "Austria", isoCode: "AT" },
  { name: "Bahamas", isoCode: "BS" },
  { name: "Bahrain", isoCode: "BH" },
  { name: "Bangladesh", isoCode: "BD" },
  { name: "Barbados", isoCode: "BB" },
  { name: "Belgium", isoCode: "BE" },
  { name: "Belize", isoCode: "BZ" },
  { name: "Benin", isoCode: "BJ" },
  { name: "Bhutan", isoCode: "BT" },
  { name: "Bolivia", isoCode: "BO" },
  { name: "Bosnia and Herzegovina", isoCode: "BO" },
  { name: "Botswana", isoCode: "BW" },
  { name: "Brazil", isoCode: "BR" },
  { name: "Brunei", isoCode: "BN" },
  { name: "Bulgaria", isoCode: "BG" },
  { name: "Burkina Faso", isoCode: "BF" },
  { name: "Burundi", isoCode: "BI" },
  { name: "Cambodia", isoCode: "KH" },
  { name: "Cameroon", isoCode: "CM" },
  { name: "Canada", isoCode: "CA" },
  { name: "Cape Verde", isoCode: "CV" },
  { name: "Central African Republic", isoCode: "CF" },
  { name: "Chad", isoCode: "TD" },
  { name: "China", isoCode: "CN" },
  { name: "Columbia", isoCode: "CO" },
  { name: "Comoros", isoCode: "KM" },
  { name: "Costa Rica", isoCode: "CR" },
  { name: "Croatia", isoCode: "HR" },
  { name: "Cuba", isoCode: "CU" },
  { name: "Cyprus", isoCode: "CY" },
  { name: "Czech Republic", isoCode: "CZ" },
  { name: "Denmark", isoCode: "DK" },
  { name: "Djibouti", isoCode: "DJ" },
  { name: "Dominica", isoCode: "DM" },
  { name: "Dominican Republic", isoCode: "DO" },
  { name: "Ecuador", isoCode: "EC" },
  { name: "Egypt", isoCode: "EG" },
  { name: "El Salvador", isoCode: "SV" },
  { name: "Equatorial Guinea", isoCode: "GQ" },
  { name: "Eritrea", isoCode: "ER" },
  { name: "Estonia", isoCode: "EE" },
  { name: "Ethiopia", isoCode: "ET" },
  { name: "Fiji", isoCode: "FJ" },
  { name: "Finland", isoCode: "FI" },
  { name: "France", isoCode: "FR" },
  { name: "Gabon", isoCode: "GA" },
  { name: "Gambia", isoCode: "GM" },
  { name: "Germany", isoCode: "DE" },
  { name: "Ghana", isoCode: "GH" },
  { name: "Greece", isoCode: "GR" },
  { name: "Greenland", isoCode: "GL" },
  { name: "Grenada", isoCode: "GD" },
  { name: "Guatemala", isoCode: "GT" },
  { name: "Guinea", isoCode: "GN" },
  { name: "Guinea-Bissau", isoCode: "GW" },
  { name: "Guyana", isoCode: "GY" },
  { name: "Haiti", isoCode: "HT" },
  { name: "Honduras", isoCode: "HN" },
  { name: "Hungary", isoCode: "HU" },
  { name: "Iceland", isoCode: "IS" },
  { name: "India", isoCode: "IN" },
  { name: "Indonesia", isoCode: "ID" },
  { name: "Ireland", isoCode: "IE" },
  { name: "Israel", isoCode: "IL" },
  { name: "Italy", isoCode: "IT" },
  { name: "Jamaica", isoCode: "JM" },
  { name: "Japan", isoCode: "JP" },
  { name: "Jordan", isoCode: "JO" },
  { name: "Kenya", isoCode: "KE" },
  { name: "Kiribati", isoCode: "KI" },
  { name: "Kuwait", isoCode: "KW" },
  { name: "Kyrgyzstan", isoCode: "KG" },
  { name: "Latvia", isoCode: "LV" },
  { name: "Lebanon", isoCode: "LB" },
  { name: "Lesotho", isoCode: "LS" },
  { name: "Liberia", isoCode: "LR" },
  { name: "Israel", isoCode: "IL" },
  { name: "Libya", isoCode: "LY" },
  { name: "Liechtenstein", isoCode: "LI" },
  { name: "Lithuania", isoCode: "LT" },
  { name: "Luxembourg", isoCode: "LU" },
  { name: "Madagascar", isoCode: "MG" },
  { name: "Malawi", isoCode: "MW" },
  { name: "Malaysia", isoCode: "MY" },
  { name: "Maldives", isoCode: "MV" },
  { name: "Mali", isoCode: "ML" },
  { name: "Malta", isoCode: "MT" },
  { name: "Marshall Islands", isoCode: "MH" },
  { name: "Mauritania", isoCode: "MR" },
  { name: "Mauritius", isoCode: "MR" },
  { name: "Mexico", isoCode: "MX" },
  { name: "Micronesia", isoCode: "FM" },
  { name: "Moldova", isoCode: "MD" },
  { name: "Monaco", isoCode: "MC" },
  { name: "Mozambique", isoCode: "MZ" },
  { name: "Myanmar", isoCode: "MM" },
  { name: "Namibia", isoCode: "NA" },
  { name: "Nauru", isoCode: "NR" },
  { name: "Nepal", isoCode: "NR" },
  { name: "Netherlands", isoCode: "NL" },
  { name: "New Zealand", isoCode: "NZ" },
  { name: "Nicaragua", isoCode: "NI" },
  { name: "Niger", isoCode: "NE" },
  { name: "Nigeria", isoCode: "NG" },
  { name: "Norway", isoCode: "NO" },
  { name: "Oman", isoCode: "OM" },
  { name: "Pakistan", isoCode: "PK" },
  { name: "Palau", isoCode: "PW" },
  { name: "Palestine", isoCode: "PS" },
  { name: "Panama", isoCode: "PA" },
  { name: "Papua New Guinea", isoCode: "PG" },
  { name: "Paraguay", isoCode: "PY" },
  { name: "Peru", isoCode: "PE" },
  { name: "Philippines", isoCode: "PH" },
  { name: "Poland", isoCode: "PL" },
  { name: "Portugal", isoCode: "PT" },
  { name: "Qatar", isoCode: "QA" },
  { name: "Romania", isoCode: "RO" },
  { name: "Rwanda", isoCode: "RW" },
  { name: "Saint Kitts and Nevis", isoCode: "KN" },
  { name: "Saint Lucia", isoCode: "LC" },
  { name: "Saint Vincent and the Grenadines", isoCode: "VC" },
  { name: "Samoa", isoCode: "WS" },
  { name: "San Marino", isoCode: "SM" },
  { name: "Sao Tome and Príncipe", isoCode: "ST" },
  { name: "Saudi Arabia", isoCode: "SA" },
  { name: "Senegal", isoCode: "SN" },
  { name: "Serbia", isoCode: "RS" },
  { name: "Seychelles", isoCode: "SC" },
  { name: "Sierra Leone", isoCode: "SL" },
  { name: "Singapore", isoCode: "SG" },
  { name: "Slovakia", isoCode: "SK" },
  { name: "Slovenia", isoCode: "SI" },
  { name: "South Africa", isoCode: "ZA" },
  { name: "Spain", isoCode: "ES" },
  { name: "Sri Lanka", isoCode: "LK" },
  { name: "Sweden", isoCode: "SE" },
  { name: "Switzerland", isoCode: "CH" },
  { name: "Tajikistan", isoCode: "TJ" },
  { name: "Tanzania", isoCode: "TZ" },
  { name: "Thailand", isoCode: "TH" },
  { name: "Togo", isoCode: "TG" },
  { name: "Tonga", isoCode: "TO" },
  { name: "Trinidad and Tobago", isoCode: "TT" },
  { name: "Tunisia", isoCode: "TN" },
  { name: "Turkey", isoCode: "TR" },
  { name: "Turkmenistan", isoCode: "TM" },
  { name: "Tuvalu", isoCode: "TV" },
  { name: "United Arab Emirates", isoCode: "AE" },
  { name: "United Kingdom", isoCode: "GB" },
  { name: "United States of America", isoCode: "US" },
  { name: "Uruguay", isoCode: "UY" },
  { name: "Uzbekistan", isoCode: "UZ" },
  { name: "Vanuatu", isoCode: "VU" },
  { name: "Venezuela", isoCode: "VE" },
  { name: "Yemen", isoCode: "YE" },
  { name: "Zambia", isoCode: "ZM" },
  { name: "Zimbabwe", isoCode: "ZW" },
];
